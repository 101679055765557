import { ITableLabel } from '@/base-ui/table/types'

import { userType } from '../types'

const userTableItem: ITableLabel<userType>[] = [
  {
    type: 'id',
    label: '排序',
    options: {
      align: 'center'
    }
  },
  {
    type: 'normal',
    label: '用户名',
    isSearchFields: true,
    prop: 'nickName',
    options: {
      align: 'center'
    }
  },
  {
    type: 'image',
    label: '头像',
    prop: 'avatarUrl',
    options: {
      align: 'center'
    },
    imgStyle: {
      width: '40px',
      height: '40px'
    }
  },
  {
    type: 'normal',
    label: '手机号',
    prop: 'phone',
    options: {
      align: 'center'
    }
  },
  {
    type: 'normal',
    label: '真实姓名',
    prop: 'realname',
    options: {
      align: 'center'
    }
  },
  {
    type: 'normal',
    label: '注册时间',
    prop: 'createAt',
    options: {
      align: 'center'
    }
  },
  {
    type: 'normal',
    label: '身份',
    prop: 'auth',
    tag: {
      type: 'success'
    },
    ifPropFields: {
      ifProp: '1',
      value1: {
        text: '工作人员',
        tagOptions: {
          type: '',
          effect: 'dark',
          size: 'large'
        }
      },
      value2: {
        text: '普通用户',
        tagOptions: {
          type: 'warning',
          effect: 'dark',
          size: 'large'
        }
      }
    }
  },
  {
    type: 'operations',
    options: {
      align: 'right'
    },
    headerItem: {
      type: 'header',
      placeholder: '用户名搜索',
      options: {
        size: 'small'
      }
    },
    operationsOption: [
      {
        value: '权限',
        btnAttribute: {
          size: 'small'
        }
      },
      {
        value: '注销',
        popconfirm: {
          title: '确认注销该用户？',
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        },
        btnAttribute: {
          type: 'danger',
          size: 'small'
        }
      }
    ]
  }
]

export { userTableItem }
